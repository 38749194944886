var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "video-container" }, [
      _c("iframe", {
        staticClass: "video",
        attrs: {
          width: "100%",
          height: "700",
          src: _vm.getUrl(),
          frameborder: "0",
          allowfullscreen: "",
          allow: "vr"
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mode == "background"
    ? _c("div", {
        staticClass: "cover-img",
        style: { backgroundImage: "url('" + _vm.url + "')" },
        on: {
          error: _vm.onError,
          click: function($event) {
            return _vm.$emit("click", $event)
          }
        }
      })
    : _c("img", {
        staticClass: "full-img",
        attrs: { alt: _vm.alt, src: _vm.url },
        on: {
          error: _vm.onError,
          click: function($event) {
            return _vm.$emit("click", $event)
          }
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
		showSearchBox: false,
		showTeleBox: false,
		showSettings: false,
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		},

		phoneView: function () {
			if (VOFFICE.phoneView) {
				return VOFFICE.phoneView;
			}
		},

	},

	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});
		//this.initDropdownEventListeners();
		var url = window.location.href;
		if (url.indexOf('#last-minute-units') > 0) {
			$('html, body').animate({
				scrollTop: $("#last-minute-units").offset().top - 100
			}, 300);
		}
	},

	methods: {
		scrollToTop: function () {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},

		toggleFontSize: function () {
			var largefont = document.body.classList.contains("largefont");

			var suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);

			if (largefont) {
				document.body.classList.remove("largefont");
				localStorage.setItem("fontsize_" + suffix, "normal");
			} else {
				document.body.classList.add("largefont");
				localStorage.setItem("fontsize_" + suffix, "large");
			}
		},
		toggleViewMode: function () {
			var darkModeEnabled = document.body.classList.contains("dark");

			var suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);

			if (darkModeEnabled) {
				document.body.classList.remove("dark");
				localStorage.setItem("viewMode_" + suffix, "light");
			} else {
				document.body.classList.add("dark");
				localStorage.setItem("viewMode_" + suffix, "dark");
			}
		},
		toggleSearchbox: function (event) {
			this.showSearchBox = !this.showSearchBox;
			var inputfeld = document.getElementById("objSearch");
			var btn = document.getElementById("objSearchBtn");
			if (this.showSearchBox) {
				this.showTeleBox = false;
				this.showSettings = false;
				btn.tabIndex = 0;
				inputfeld.focus();
			} else {
				inputfeld.blur();
				btn.tabIndex = -1;
			}
		},
		toggleTelebox: function (event) {
			this.showTeleBox = !this.showTeleBox;
			var telebox = document.getElementById("tele-box");
			var phoneLink = document.getElementById("phoneLink");
			if (this.showTeleBox) {
				telebox.focus();
				phoneLink.tabIndex = 0;
				this.showSearchBox = false;
				this.showSettings = false;
			} else {
				telebox.blur();
				phoneLink.tabIndex = -1;
			}
		},
		toggleSettings: function (event) {
			this.showSettings = !this.showSettings;
			var settings = document.getElementById("settings");
			var contrastLink = document.getElementById("contrastLink");
			var typeLink = document.getElementById("typeLink");
			if (this.showSettings) {
				settings.focus();
				contrastLink.tabIndex = 0;
				typeLink.tabIndex = 0;
				this.showSearchBox = false;
				this.showTeleBox = false;
			} else {
				settings.blur();
				contrastLink.tabIndex = -1;
				typeLink.tabIndex = -1;
			}
		},
		phoneLink: function () {
			if (VOFFICE.phoneLink) {
				var el = document.getElementById('preloader');

				window.location.href = VOFFICE.phoneLink;
				if (el) {
					el.style.display = "none";
				}
			}
		},
		navbarToggle: function () {

			$('.main-menu').slideToggle();
			$('.navbar-v1').removeClass('open-by-mob');

			this.showTeleBox = false;
			this.showSearchBox = false;


		},

		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		},
		toggleDropdownOnEnter: function (event) {
			if (event.keyCode === 13) {
				event.preventDefault();

				var dropdownToggle = event.target;
				var dropdownMenu = dropdownToggle.nextElementSibling;


				var isOpen = dropdownToggle.classList.contains('show');

				if (!isOpen) {
					dropdownToggle.classList.add('show');
					dropdownMenu.classList.add('show');
				}
			}
		},


		initDropdownEventListeners: function () {
			var dropdownToggles = document.querySelectorAll('.dropdown-toggle');
			dropdownToggles.forEach(toggle => {
				toggle.addEventListener('keydown', this.toggleDropdownOnEnter);
			});
		},

	}


};
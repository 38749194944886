import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			imagesForModal: []
		};
	},
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var req = {
			fields: this.resultFields,
			filter: {
				sauna: true
			},
			sorting: 'random',
			max: 6
		};

		doReq('searchUnits', req)
			.then((res) => {
				//console.log(res.units);
				this.units = res.units;
			});

	},

	updated: function () {
		this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();


	},
	methods: {
		applySlider: function () {
			$(".owl-carousel.pets-units").owlCarousel({
				loop: false,
				margin: VOFFICE.settings.olwCarouselMargin,
				smartSpeed: 1000,
				autoplay: false,
				nav: VOFFICE.settings.olwCarouselNav,
				dots: true,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					996: {
						items: 3
					},
					1200: {
						items: 3

					}
				}
			});
		},
		getUnitSearchTarget: function (id) {
			return 'unit_' + id;
		},
	}

};